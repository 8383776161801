import { type FC, useEffect, useState } from 'react';
import { useTeams } from 'msteams-react-base-component';
import { jwtDecode } from 'jwt-decode';
import * as microsoftTeams from '@microsoft/teams-js';
import { Loading, TokenProvider, UserAuthProvider, EClientType } from 'nulia-ui';
import Routes from './components/Routes';

const Main: FC = () => {
    const [{ inTeams }] = useTeams();
    const [token, setToken] = useState<string | undefined>();
    const [oid, setOid] = useState<string | undefined>();
    const [tid, setTid] = useState<string | undefined>();
    const [error, setError] = useState<string>();

    useEffect(() => {
        if (inTeams === true) {
            microsoftTeams.authentication.getAuthToken({
                successCallback: (token: string) => {
                    const decoded: { [key: string]: any } = jwtDecode(token) as {
                        [key: string]: any;
                    };
                    setToken(token);
                    setOid(decoded!.oid);
                    setTid(decoded!.tid);
                    microsoftTeams.appInitialization.notifySuccess();
                },
                failureCallback: (message: string) => {
                    setError(message);
                    microsoftTeams.appInitialization.notifyFailure({
                        reason: microsoftTeams.appInitialization.FailedReason.AuthFailed,
                        message
                    });
                }
                // resources: [process.env.TAB_APP_URI as string],
            });
        } else {
            setError('Not in Microsoft Teams');
        }
    }, [inTeams]);

    if (oid && tid && token)
        return (
            <TokenProvider propOid={oid} prodTid={tid} propToken={token} propClientType={EClientType.TEAMS}>
                <UserAuthProvider>
                    <Routes />
                </UserAuthProvider>
            </TokenProvider>
        );
    return <Loading />;
};

export default Main;
